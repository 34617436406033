import * as moment from "moment";

export function eventsToMatchDays(events) {

    let matchDaysMap = new Map();

    if (!events) {
        return [];
    }

    events.forEach(v => {
        const renderedDate = moment.unix(v.event.times.advertised_start_time).format('dddd Do MMMM YYYY'); // e.g. 'Wednesday 8th July 2020

        let matchDay = matchDaysMap.get(renderedDate);
        if (matchDay === undefined) {
            matchDay = {
                renderedDate: renderedDate,
                matches: []
            };
            matchDaysMap.set(renderedDate, matchDay)
        }

        const m = eventToMatch(v);
        if (m) {
            matchDay.matches.push(m);
        }

    });

    return Array.from(matchDaysMap.values());
}

function findHomeTeam(competitors) {
    return findTeam(competitors, "home");
}

function findAwayTeam(competitors) {
    return findTeam(competitors, "away");
}

function findTeam(competitors, role) {
    return competitors.find(element => element.role === role);
}

export function eventToMatch(ae) {

    const renderedTime = moment.unix(ae.event.times.advertised_start_time).format('H:mm'); // 18:00

    const homeTeam = findHomeTeam(ae.event.competitors);
    const awayTeam = findAwayTeam(ae.event.competitors);

    const pm = findPrimaryMarket(ae.aggregatedMarkets);

    if (!pm) {
        //console.log("pm is null: " + ae.event.name);
        return null;
    }

    const providerPrices = buildProviderPriceMap(pm);

    let highHomePrice = "-";
    if (pm.aggregatedOptions && pm.aggregatedOptions.Home && pm.aggregatedOptions.Home.highPrice) {
        highHomePrice = pm.aggregatedOptions.Home.highPrice.toFixed(2);
    }
    let highDrawPrice = "-";
    if (pm.aggregatedOptions && pm.aggregatedOptions.Draw && pm.aggregatedOptions.Draw.highPrice) {
        highDrawPrice = pm.aggregatedOptions.Draw.highPrice.toFixed(2);
    }
    let highAwayPrice = "-";
    if (pm.aggregatedOptions && pm.aggregatedOptions.Away && pm.aggregatedOptions.Away.highPrice) {
        highAwayPrice = pm.aggregatedOptions.Away.highPrice.toFixed(2);
    }

    let lam = pm.lowAggregatedMargin ? pm.lowAggregatedMargin.toFixed(2) : 0;
    if (lam) {
        lam = lam - 100;
        lam = lam.toFixed(2);
    }

    const showDraw = pm && pm.aggregatedOptions && pm.aggregatedOptions["Draw"];

    return {
        id: ae.event.id,
        renderedTime: renderedTime,
        competition: ae.event.categories.competition.name,
        country: {
            name: ae.event.categories.competition.country.name,
            iso3166_2: ae.event.categories.competition.country.iso3166_alpha_2,
        },
        home: {
            name: homeTeam.name,
            country: {
                name: homeTeam.country.name,
                iso3166_2: homeTeam.country.iso3166_alpha_2,
            },
        },
        homeForm: 'WWWWW', // TODO
        away: {
            name: awayTeam.name,
            country: {
                name: awayTeam.country.name,
                iso3166_2: awayTeam.country.iso3166_alpha_2,
            },
        },
        awayForm: 'WDLLW', // TODO
        providerPrices: providerPrices,
        lowAggregatedMargin: lam,
        highHomePrice: highHomePrice,
        highDrawPrice: highDrawPrice,
        highAwayPrice: highAwayPrice,
        primaryMarket: pm,
        showDraw: showDraw
    };
}

function findPrimaryMarket(aggregatedMarkets) {

    if (!aggregatedMarkets) {
        return null;
    }

    let pm = aggregatedMarkets.H2h_3way;
    if (pm) {
        return pm
    }

    return aggregatedMarkets.H2h_2way;
}

function findHomeOption(aggregatedMarket, provider) {
    if (!aggregatedMarket || !aggregatedMarket.aggregatedOptions) {
        return null;
    }
    const ao = aggregatedMarket.aggregatedOptions.Home;
    if (!ao || !ao.optionProviders) {
        return null;
    }
    return ao.optionProviders[provider];
}

function findAwayOption(aggregatedMarket, provider) {
    if (!aggregatedMarket || !aggregatedMarket.aggregatedOptions) {
        return null;
    }
    const ao = aggregatedMarket.aggregatedOptions.Away;
    if (!ao || !ao.optionProviders) {
        return null;
    }
    return ao.optionProviders[provider];
}

function findDrawOption(aggregatedMarket, provider) {
    if (!aggregatedMarket || !aggregatedMarket.aggregatedOptions) {
        return null;
    }
    const ao = aggregatedMarket.aggregatedOptions.Draw;
    if (!ao || !ao.optionProviders) {
        return null;
    }
    return ao.optionProviders[provider];
}

function getMargin(aggregatedMarket, provider) {

    if (!aggregatedMarket || !aggregatedMarket.marketProviders) {
        return "-";
    }

    let mp = aggregatedMarket.marketProviders[provider];

    if (!mp) {
        return "-";
    }

    if (!mp.marketDetails) {
        return "-";
    }

    if (!mp.marketDetails.margin) {
        return "-";
    }

    const m = mp.marketDetails.margin - 100;
    return m.toFixed(2);
}

function buildProviderPrices(aggregatedMarket, provider) {
    const ho = findHomeOption(aggregatedMarket, provider);
    const dOp = findDrawOption(aggregatedMarket, provider);
    const ao = findAwayOption(aggregatedMarket, provider);
    const margin = getMargin(aggregatedMarket, provider);

    return {
        provider: provider,
        homePrice: ho && ho.optionDetails.price && ho.optionDetails.price.win_dec ? ho.optionDetails.price.win_dec.toFixed(2) : "-",
        isHomePriceHigh: ho ? ho.isPriceHigh : false,
        isHomePriceLow: ho ? ho.isPriceLow : false,
        drawPrice: dOp && dOp.optionDetails.price && dOp.optionDetails.price.win_dec ? dOp.optionDetails.price.win_dec.toFixed(2) : "-",
        isDrawPriceHigh: dOp ? dOp.isPriceHigh : false,
        isDrawPriceLow: dOp ? dOp.isPriceLow : false,
        awayPrice: ao && ao.optionDetails.price && ao.optionDetails.price.win_dec ? ao.optionDetails.price.win_dec.toFixed(2) : "-",
        isAwayPriceHigh: ao ? ao.isPriceHigh : false,
        isAwayPriceLow: ao ? ao.isPriceLow : false,
        margin: margin
    }
}


function buildProviderPriceMap(market) {

    const bbet = buildProviderPrices(market, "bbet-au");
    const betdeluxe = buildProviderPrices(market, "betdeluxe-au");
    const ladbrokes = buildProviderPrices(market, "ladbrokes-au");
    const neds = buildProviderPrices(market, "neds-au");
    const palmerbet = buildProviderPrices(market, "palmerbet-au");
    const pointsbet = buildProviderPrices(market, "pointsbet-au");
    const robwaterhouse = buildProviderPrices(market, "robwaterhouse-au");
    const sportsbet = buildProviderPrices(market, "sportsbet-au");
    const tab = buildProviderPrices(market, "tab-au");
    return {
        bbet: bbet,
        betdeluxe: betdeluxe,
        ladbrokes: ladbrokes,
        neds: neds,
        palmerbet: palmerbet,
        pointsbet: pointsbet,
        robwaterhouse: robwaterhouse,
        sportsbet: sportsbet,
        tab: tab,
    }
}