import React, {useEffect, useState} from "react";
import {Marker, Popup, Rectangle, useMap} from "react-leaflet";
import {Icon} from "leaflet";
import penPointSVG from "../assets/images/map/markers/darkgreendot.png";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Landscape from '@material-ui/icons/Landscape';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {OSMChangeSet} from "../model/OSMChangeSet";

const pointMarkerIcon = new Icon({
    iconUrl: penPointSVG,
    iconSize: [5, 5]
});

export default function OSMRecentChangeSetMarkers() {

    // NOTE: useState returns a stateful value and a function to update it
    // the approach on the left here is 'array destructuring'
    const [data, setData] = useState<OSMChangeSet[]>();
    const [currentZoom, setCurrentZoom] = useState<number>(2);

    // NOTE: the 2nd param here is important. Without it, useEffect will continually cause re-renders
    // 2nd param is:
    // deps: If present, effect will only activate if the values in the list change. Supplying an empty array implies to only run on initial render
    useEffect(() => {

        const fetchAsync = async () => {
            await fetchContent();
        };

        fetchAsync();

    }, []);

    const fetchContent = async () => {

        let url = "/changesets/all";

        // FIXME uncomment this to test locally
        // url = "./testdata/sample_changeset_data.json";

        fetch(url)
            .then(res => res.json())
            .then((data) => {
                setData(data);
                setCurrentZoom(2);
            })
            .catch(error => {
                setData([]);
                console.log("error: " + error.stack)
            });

    };

    const mapInstance = useMap();

    const handleZoom = (zoom: number, data: OSMChangeSet) => {
        mapInstance.setZoom(zoom);
        mapInstance.panTo([data.maxLat, data.minLon]);
        setCurrentZoom(zoom);
    };

    const useStyles = makeStyles((theme) => ({
        tinyIcon: {
            '& svg': {
                fontSize: 6
            }
        },
        smallIcon: {
            '& svg': {
                fontSize: 10
            }
        },
        mediumIcon: {
            '& svg': {
                fontSize: 14
            }
        },
        largeIcon: {
            '& svg': {
                fontSize: 19
            }
        },
        veryLargeIcon: {
            '& svg': {
                fontSize: 25
            }
        }
    }));
    const classes = useStyles();

    const popupFromData = (osmChangeSet: OSMChangeSet) => {

        return <Popup>
            <Grid container spacing={1} direction={"column"}>
                <Grid item>
                    <b>{osmChangeSet.createdAt}</b>
                </Grid>
                <Grid item>
                    User:&nbsp;
                    <Link href={"https://www.openstreetmap.org/user/" + osmChangeSet.user} target="_blank"
                          rel="noopener">
                        {osmChangeSet.user}
                    </Link>
                </Grid>
                <Grid item>
                    <i>'{osmChangeSet.comment}'</i>
                </Grid>
                <Grid item>
                    Source: {osmChangeSet.source}
                </Grid>
                <Grid item>
                    Changeset:&nbsp;
                    <Link href={"https://www.openstreetmap.org/changeset/" + osmChangeSet.id} target="_blank"
                          rel="noopener">
                        {osmChangeSet.id}
                    </Link>
                </Grid>
                <Divider/>
                <Grid item>
                    <Grid container spacing={1} justify={"center"} alignItems={"center"}>
                        <Grid item>
                            <ButtonGroup color="primary" aria-label="outlined primary button group" size={"small"}>
                                <Button variant={currentZoom === 2 ? "contained" : "text"} className={classes.tinyIcon}
                                        onClick={() => handleZoom(2, osmChangeSet)}><Landscape/></Button>
                                <Button variant={currentZoom === 7 ? "contained" : "text"} className={classes.smallIcon}
                                        onClick={() => handleZoom(7, osmChangeSet)}><Landscape/></Button>
                                <Button variant={currentZoom === 10 ? "contained" : "text"}
                                        className={classes.mediumIcon}
                                        onClick={() => handleZoom(10, osmChangeSet)}><Landscape/></Button>
                                <Button variant={currentZoom === 15 ? "contained" : "text"}
                                        className={classes.largeIcon}
                                        onClick={() => handleZoom(15, osmChangeSet)}><Landscape/></Button>
                                <Button variant={currentZoom === 25 ? "contained" : "text"}
                                        className={classes.veryLargeIcon}
                                        onClick={() => handleZoom(25, osmChangeSet)}><Landscape/></Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Popup>
    };

    const markerFromData = (osmChangeSet: OSMChangeSet) => {
        if (!osmChangeSet.maxLat || !osmChangeSet.maxLon) {
            return null;
        }
        const popup = popupFromData(osmChangeSet);
        return <div>
            <Marker position={[osmChangeSet.maxLat, osmChangeSet.minLon]}
                    key={osmChangeSet.maxLat + "_1"} icon={pointMarkerIcon}>
                {popup}
            </Marker>
            <Rectangle bounds={[[osmChangeSet.minLat, osmChangeSet.minLon],
                [osmChangeSet.maxLat, osmChangeSet.maxLon],]} pathOptions={{color: 'black'}}/>
        </div>
    };

    const items = [];

    if (data) {
        for (const [, value] of data.entries()) {
            const m = markerFromData(value);
            if (m) {
                items.push(m);
            }
        }
    }

    return (
        <React.Fragment>
            {items}
        </React.Fragment>
    );

}