import React, { useContext, useEffect, useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import { Icon, LatLng } from 'leaflet';
import closeContact from '../assets/images/map/markers/coronavirus_red.png';
import casualContact from '../assets/images/map/markers/coronavirus_orange.png';
import lowRiskContact from '../assets/images/map/markers/coronavirus_yellow.png';
import { useMapEvents } from 'react-leaflet';
import dayjs from 'dayjs';
import { ExposureSitesContext } from '../contexts/ExposureSitesContext';
import { ExposureSite } from '../model/ExposureSite';
import ExposureSiteInfoDialog from "./ExposureSiteInfoDialog";
import ExposureSitePopupContent from "./ExposureSitePopupContent";

const closeContactSmall = new Icon({
  iconUrl: closeContact,
  iconSize: [15, 15],
});

const closeContactMedium = new Icon({
  iconUrl: closeContact,
  iconSize: [25, 25],
});

const closeContactLarge = new Icon({
  iconUrl: closeContact,
  iconSize: [50, 50],
});

const casualContactSmall = new Icon({
  iconUrl: casualContact,
  iconSize: [15, 15],
});

const casualContactMedium = new Icon({
  iconUrl: casualContact,
  iconSize: [25, 25],
});

const casualContactLarge = new Icon({
  iconUrl: casualContact,
  iconSize: [50, 50],
});

const lowRiskContactSmall = new Icon({
  iconUrl: lowRiskContact,
  iconSize: [15, 15],
});

const lowRiskContactMedium = new Icon({
  iconUrl: lowRiskContact,
  iconSize: [25, 25],
});

const lowRiskContactLarge = new Icon({
  iconUrl: lowRiskContact,
  iconSize: [50, 50],
});

interface defaultStateView {
  center: LatLng;
  zoom: number;
}

//const defaultStateViews: Map<string, defaultStateView> = new Map<string, defaultStateView>();
const defaultStateViews: Map<string, defaultStateView> = new Map<
  string,
  defaultStateView
>([
  [
    'ACT',
    {
      center: new LatLng(-35.2813046,149.124822),
      zoom: 10,
    },
  ],
  [
    'NSW',
    {
      center: new LatLng(-33.047927, 150.0517926),
      zoom: 7,
    },
  ],
  [
    'NT',
    {
      center: new LatLng(-18.4292717,129.0061701),
      zoom: 6,
    },
  ],
  [
    'SA',
    {
      center: new LatLng(-35.0004451, 138.3309724),
      zoom: 8,
    },
  ],
  [
    'QLD',
    {
      center: new LatLng(-25.6728255, 151.7455136),
      zoom: 7,
    },
  ],
  [
    'TAS',
    {
      center: new LatLng(-42.0456997,146.4180456),
      zoom: 7,
    },
  ],
  [
    'WA',
    {
      center: new LatLng(-27.1450658,115.9434403),
      zoom: 5,
    },
  ],
  [
    'VIC',
    {
      center: new LatLng(-37.0722342,145.0729549),
      zoom: 8,
    },
  ],
]);

export default function CovidExposureSiteMarkers() {
  // NOTE: useState returns a stateful value and a function to update it
  // the approach on the left here is 'array destructuring'
  ///const [data, setData] = useState();
  const [currentZoom, setCurrentZoom] = useState<number>(12);

  const [exposureSitesState, setExposureSitesState] =
    useContext(ExposureSitesContext);

  // console.log('mapMarkers=' + exposureSitesState.exposureSites.length);

  const mapInstance = useMap();

  useEffect(() => {
    const stateView = defaultStateViews.get(exposureSitesState.selectedState);
    if (!stateView) {
      return;
    }
    mapInstance.setView(stateView.center, stateView.zoom);
  }, [mapInstance, exposureSitesState.selectedState]);

  // const bounds = [
  //     [-43.6345972634, 113.338953078],
  //     [-10.6681857235, 153.569469029],
  // ]
  // mapInstance.fitBounds(bounds);
  //mapInstance.setMaxBounds(bounds);

  const mapEvents = useMapEvents({
    zoomend: () => {
      setCurrentZoom(mapEvents.getZoom());
    },
  });

  const handleZoom = (zoom: number, data: ExposureSite) => {
    mapInstance.setZoom(zoom);
    mapInstance.panTo([data.latitude, data.longitude]);
    setCurrentZoom(zoom);
  };


  const popupFromData = (data: ExposureSite) => {
    const renderedDate = dayjs
      .unix(data.day_unix_seconds)
      .format('dddd D MMMM YYYY'); // e.g. 'Wednesday 8th July 2020

    return (
      <Popup>
        <ExposureSitePopupContent exposureSite={data} currentZoom={currentZoom} handleZoom={handleZoom}/>
      </Popup>
    );
  };

  const markerFromData = (data: ExposureSite) => {
    if (!data.latitude || !data.longitude) {
      return null;
    }
    const popup = popupFromData(data);

    let icon = closeContactSmall;
    switch (data.contact_type) {
      case "LowRisk":
        if (currentZoom >= 15) {
          icon = lowRiskContactLarge;
        } else if (currentZoom >= 10) {
          icon = lowRiskContactMedium;
        } else {
          icon = lowRiskContactSmall
        }
        break
      case "Casual":
        if (currentZoom >= 15) {
          icon = casualContactLarge;
        } else if (currentZoom >= 10) {
          icon = casualContactMedium;
        } else {
          icon = casualContactSmall;
        }
        break
      case "Close":
      default:
        if (currentZoom >= 15) {
          icon = closeContactLarge;
        } else if (currentZoom >= 10) {
          icon = closeContactMedium;
        } else {
          let icon = closeContactSmall;
        }
    }


    return (
      <div>
        <Marker
          position={[data.latitude, data.longitude]}
          key={data.latitude + '_1'}
          icon={icon}
        >
          {popup}
        </Marker>
      </div>
    );
  };

  const items = [];

  for (const es of exposureSitesState.exposureSites) {
    const m = markerFromData(es);
    if (m) {
      items.push(m);
    }
  }

  return <React.Fragment>{items}</React.Fragment>;
}
