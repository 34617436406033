import React, {Component} from 'react';
import {Menu, Tab} from 'semantic-ui-react'
import EventList from "../components/EventList";

export default class VolleyballTabs extends Component {

    renderTabContent(title, competition) {
        return <Tab.Pane attached={false} key={competition} style={{padding: "0", border: "0"}}>
            <EventList title={title} sport="volleyball" competition={competition}/>
        </Tab.Pane>
    }

    render() {
        const panes = [
            {
                menuItem: (
                    <Menu.Item key="coming-up" style={{fontSize: "13px"}}>
                        Coming Up
                    </Menu.Item>),
                render: () => this.renderTabContent("Coming Up", "")
            },
        ];

        return (
            <Tab menu={{pointing: true}} panes={panes} />
        );
    }


}
