import React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import { ExposureSite } from '../model/ExposureSite';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface Props {}

export default function SiteInfoIconWithDialog(props: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        aria-label="info"
        onClick={handleClickOpen}
        edge="end"
        color="inherit"
      >
        <InfoIcon />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Typography variant={'body2'}>
            This site plots Covid-19 exposure sites as reported by the QLD
            Government over the past 14 days{' '}
            <a
              href="https://www.qld.gov.au/health/conditions/health-alerts/coronavirus-covid-19/current-status/contact-tracing"
              target="_blank"
            >
              here.
            </a>
          </Typography>
          <br />
          <Typography variant={'body2'}>
            The QLD Government site should remain your single source of truth.
          </Typography>
          <br />
          <Typography variant={'body2'}>
            This site is able to plot ~85% of data automatically via address
            lookups. It does not plot public transport routes.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
