import React, {Component} from "react";
import DayMatches from "./match/DayMatches";
import {eventsToMatchDays} from "./model";

export default class EventList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            matchDays: [],
            loading: false,
        };
    }

    state = {
        matchDays: []
    };

    componentDidMount() {
        this.fetchContent();
    }

    fetchContent() {

        this.setState({
            loading: true,
        });

        let url = new URL("/api/events", document.baseURI);

        if (this.props.sport) {
            url.searchParams.append("sport", this.props.sport);
        }

        if (this.props.competition) {
            url.searchParams.append("competition", this.props.competition);
        }

        if (this.props.limit) {
            url.searchParams.append("limit", this.props.limit);
        }

        // FIXME uncomment this to test locally
        // url = "./testdata/sample_priceshield.json";

        fetch(url)
            .then(res => res.json())
            .then((data) => {
                this.setState({
                    matchDays: eventsToMatchDays(data),
                    loading: false,
                });

            })
            .catch(error => {
                this.setState({
                    matchDays: [],
                    loading: false,
                });
                console.log("error: " + error.stack)
            });

    }

    render() {

        const items = [];

        if (this.state.loading) {
            items.push(<div key={"loading"}><br/>Loading...</div>);
        } else {
            for (const [, value] of this.state.matchDays.entries()) {
                // items.push(<DayMatches renderedDate={value.renderedDate} matches={value.matches}
                //                        key={value.renderedDate}/>);
                items.push(<DayMatches renderedDate={value.renderedDate} matches={value.matches}
                                       key={value.renderedDate + "_2"}/>);
            }

            if (items.length === 0) {
                items.push(<div key={"none"}><br/>None Scheduled</div>);
            }
        }

        return (
            <React.Fragment>
                {items}
            </React.Fragment>
        );

    }

}