import React, {Component} from 'react';
import {Container, Image, Menu, Segment, Tab} from 'semantic-ui-react'
import '../App.css';
import SoccerTabs from "./sports/SoccerTabs";
import AmericanFootballTabs from "./sports/AmericanFootballTabs";
import BasketballTabs from "./sports/BasketballTabs";
import BaseballTabs from "./sports/BaseballTabs";
import CricketTabs from "./sports/CricketTabs";
import VolleyballTabs from "./sports/VolleyballTabs";

import soccerImage from "./assets/images/menu_icons/football.png";
import americanFootballImage from "./assets/images/menu_icons/football-152827_640.png";
import basketballImage from "./assets/images/menu_icons/basketball.png";
import baseballImage from "./assets/images/menu_icons/baseball-157928_640.png";
import cricketImage from "./assets/images/menu_icons/cricket.png";
import volleyballImage from "./assets/images/menu_icons/volleyball-25765_640.png";

export default class PriceComparison extends Component {

    render() {

        const panes = [
            {
                menuItem: (
                    <Menu.Item key="soccer">
                        <Image src={soccerImage} style={{width: 40, height: 40}} alt="Soccer"
                               centered/>
                    </Menu.Item>),
                render: () => <SoccerTabs/>
            },
            {
                menuItem: (
                    <Menu.Item key="american_football">
                        <Image src={americanFootballImage} style={{width: 40, height: 40}} alt="American Football"
                               centered/>
                    </Menu.Item>),
                render: () => <AmericanFootballTabs/>
            },
            {
                menuItem: (
                    <Menu.Item key="basketball">
                        <Image src={basketballImage} style={{width: 40, height: 40}} alt="Basketball"
                               centered/>
                    </Menu.Item>),
                render: () => <BasketballTabs/>
            },
            {
                menuItem: (
                    <Menu.Item key="baseball">
                        <Image src={baseballImage} style={{width: 40, height: 40}} alt="Baseball"
                               centered/>
                    </Menu.Item>),
                render: () => <BaseballTabs/>
            },
            {
                menuItem: (
                    <Menu.Item key="cricket">
                        <Image src={cricketImage} style={{width: 40, height: 40}} alt="Cricket"
                               centered/>
                    </Menu.Item>),
                render: () => <CricketTabs/>
            },
            {
                menuItem: (
                    <Menu.Item key="volleyball">
                        <Image src={volleyballImage} style={{width: 40, height: 40}} alt="Volleyball"
                               centered/>
                    </Menu.Item>),
                render: () => <VolleyballTabs/>
            },
        ];

        return (
            <div className="PriceComparison">
                <Container fluid textAlign="center" className="pageFooter">
                    <Segment inverted>
                        This App has microservices for fixture collection, odds collection, data aggregation and API gateways
                        <br/>
                        Data is persisted in MongoDB
                        <br/>
                        Click on the graph icons to see price fluctuations. Note that some sports/leagues may be out of season
                    </Segment>
                </Container>
                <Container>
                    <Tab menu={{secondary: true, pointing: true}} panes={panes}/>
                </Container>
            </div>
        );
    }

}

