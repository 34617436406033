import CovidExposureSites from "./CovidExposureSites";
import {ExposureSitesContextProvider} from "../contexts/ExposureSitesContext";
import React from "react";
import LightTheme from "../assets/theme/LightTheme";
import {MuiThemeProvider} from '@material-ui/core';

export default function CovidExposureSitesWrapper() {
    return (
        <MuiThemeProvider theme={LightTheme}>
            <ExposureSitesContextProvider>
                <CovidExposureSites/>
            </ExposureSitesContextProvider>
        </MuiThemeProvider>
    );
}