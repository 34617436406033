import Typography from "@material-ui/core/Typography";
import React from "react";

interface Props {
    contactType: string;
}

export default function ContactType(props: Props) {
    let color: string;
    let contactType: string;
    switch (props.contactType) {
        case 'Close':
            color = 'red'
            contactType = 'Close Contact';
            break;
        case 'Casual':
            color = '#F16A05CE'
            contactType = 'Casual Contact';
            break;
        case 'Interstate':
            color = 'red'
            contactType = 'Interstate';
            break;
        default :
            contactType = 'Low Risk';
            color = '#F1B605CE'
    }

    return (
        <div>
            <Typography
                variant={'body1'}
                style={{ fontWeight: "bold", fontSize: 13, color: color }}
            >
                {contactType}
            </Typography>
            {/*<Typography*/}
            {/*    variant={'body2'}>*/}
            {/*    Please follow the health advice as per current QLD Health recommendations*/}
            {/*    https://www.qld.gov.au/health/conditions/health-alerts/coronavirus-covid-19/current-status/contact-tracing*/}
            {/*</Typography>*/}
        </div>
    )
}