import { createTheme } from '@material-ui/core/styles';

const LightTheme = createTheme({
  palette: {
    primary: {
      light: '#123e65',
      main: '#123e65',
      dark: '#123e65',
      contrastText: '#fff',
    },
    secondary: {
      light: '#0981e6',
      main: '#0981e6',
      dark: '#0981e6',
      contrastText: '#fff',
    },
  },
});

export default LightTheme;
