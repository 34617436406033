import React from 'react';
import {Theme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import {ExposureSite} from '../model/ExposureSite';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface Props {
}

export default function NoNewDataDialog(props: Props) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
        >
            <DialogContent dividers>
                <Typography variant={'h6'} color={'secondary'}>
                    Update
                </Typography>
                <Typography variant={'body2'}>
                    <br/>
                    From 31 December 2021 onwards, the QLD Government will only notify of major outbreak venues or super-spreader events.
                    More more information, please refer to the QLD Government site{' '}
                    <a
                        href="https://www.qld.gov.au/health/conditions/health-alerts/coronavirus-covid-19/current-status/contact-tracing"
                        target="_blank"
                    >
                        here.
                    </a>
                    <br/>
                    <br/>
                    Any data on this site prior to 31 December 2021 represents a historical record
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
