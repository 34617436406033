import React, { createContext, FC, useContext, useState } from 'react';
import { ExposureSite } from '../model/ExposureSite';

export type ExposureSitesContextState = {
  exposureSites: ExposureSite[];
  selectedState: string;
  selectedContactType: string; // TODO
  //setExposureSites: (exposureSites: ExposureSite[]) => void;
};

const contextDefaultValues: ExposureSitesContextState = {
  exposureSites: [],
  selectedState: 'QLD',
  selectedContactType: 'All',
  //setExposureSites: () => {}
};

export const ExposureSitesContext = createContext<
  [
    ExposureSitesContextState,
    (exposureSitesContextState: ExposureSitesContextState) => void
  ]
>([contextDefaultValues, () => {}]);

export const useExposureSitesContext = () => useContext(ExposureSitesContext);

export const ExposureSitesContextProvider: FC = (props) => {
  const [exposureSitesState, setExposureSitesState] =
    useState(contextDefaultValues);
  const defaultSessionContext: [
    ExposureSitesContextState,
    typeof setExposureSitesState
  ] = [exposureSitesState, setExposureSitesState];

  return (
    <ExposureSitesContext.Provider value={defaultSessionContext}>
      {props.children}
    </ExposureSitesContext.Provider>
  );
};
