import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Landscape from "@material-ui/icons/Landscape";
import React from "react";
import {ExposureSite} from "../model/ExposureSite";
import dayjs from "dayjs";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import ContactType from "./ContactType";

interface IProps {
    exposureSite: ExposureSite
    currentZoom: number
    handleZoom: (zoom: number, data: ExposureSite) => void
}

export default function ExposureSitePopupContent(props: IProps) {

    const useStyles = makeStyles((theme) => ({
        smallIcon: {
            '& svg': {
                fontSize: 8,
            },
        },
        mediumIcon: {
            '& svg': {
                fontSize: 17,
            },
        },
        largeIcon: {
            '& svg': {
                fontSize: 25,
            },
        },
    }));
    const classes = useStyles();

    const renderedDate = dayjs
        .unix(props.exposureSite.day_unix_seconds)
        .format('dddd D MMMM YYYY'); // e.g. 'Wednesday 8th July 2020

    return (
        <React.Fragment>
        <Grid container spacing={1} direction={'column'}>
            <Grid item>
                <b> {props.exposureSite.location}</b>
            </Grid>
            <Grid item>{renderedDate}</Grid>
            <Grid item>{props.exposureSite.time_text}</Grid>
            <Grid item>{props.exposureSite.address}</Grid>
            <Grid item><b>{props.exposureSite.suburb}</b></Grid>
            <Divider />
            <Grid item>
                <ContactType contactType={props.exposureSite.contact_type}/>
            </Grid>
            <Divider />
            <Grid item>
                <Grid
                    container
                    spacing={1}
                    justify={'center'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <ButtonGroup
                            color="primary"
                            aria-label="outlined primary button group"
                            size={'small'}
                        >
                            <Button
                                variant={props.currentZoom === 5 ? 'contained' : 'text'}
                                className={classes.smallIcon}
                                onClick={() => props.handleZoom(5, props.exposureSite)}
                            >
                                <Landscape />
                            </Button>
                            <Button
                                variant={props.currentZoom === 11 ? 'contained' : 'text'}
                                className={classes.mediumIcon}
                                onClick={() => props.handleZoom(11, props.exposureSite)}
                            >
                                <Landscape />
                            </Button>
                            <Button
                                variant={props.currentZoom === 16 ? 'contained' : 'text'}
                                className={classes.largeIcon}
                                onClick={() => props.handleZoom(16, props.exposureSite)}
                            >
                                <Landscape />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </React.Fragment>
    )
}