import React, {Component} from "react";

import ReactApexChart from 'react-apexcharts';

export default class OptionFluctuationsChart extends Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    buildProviderSeries(providerWithData) {
        return {
            name: providerWithData.provider,
            data:  providerWithData.data
        };
    }

    buildColours() {
        const colours = [];
        for (const [, rawSeries] of Object.entries(this.props.series)) {
            switch (rawSeries.provider) {
                case "betdeluxe-au":
                    colours.push('rgba(185,57,48,0.73)');
                    break;
                case "ladbrokes-au":
                    colours.push('#cb170c');
                    break;
                case "neds-au":
                    colours.push('#e38628');
                    break;
                case "pointsbet-au":
                    colours.push('#090b1e');
                    break;
                case "palmerbet-au":
                    colours.push('#9eb0d5');
                    break;
                case "robwaterhouse-au":
                    colours.push('rgba(56,22,185,0.73)');
                    break;
                case "sportsbet-au":
                    colours.push('#455af4');
                    break;
                case "tab-au":
                    colours.push('#41823c');
                    break;
                default:
                    colours.push('#737373');
            }
        }
        return colours;
    }

    buildStrokeDashArray() {
        const dashes = []; // [5, 8, 0, 0]
        for (const [, rawSeries] of Object.entries(this.props.series)) {
            switch (rawSeries.provider) {
                case "ladbrokes-au":
                    dashes.push(5);
                    break;
                case "neds-au":
                    dashes.push(8);
                    break;
                case "palmerbet-au":
                    dashes.push(8);
                    break;
                default:
                    dashes.push(0);
            }
        }
        return dashes;
    }

    render() {

        const series = [];
        for (const [, providerWithData] of Object.entries(this.props.series)) {
            series.push(
                this.buildProviderSeries(providerWithData)
            );
        }

        const colours = this.buildColours();
        const strokeDashArray = this.buildStrokeDashArray();

        const showLegend = true; //this.state.width > 700;

        const options = {
            chart: {
                type: 'area',
                stacked: false,
                height: 250,
                zoom: {
                    type: 'x',
                    enabled: false,
                    autoScaleYaxis: true
                },
                toolbar: {
                    //autoSelected: 'zoom'
                    enabled: false
                },
                animations: {
                    enabled: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 3,
            },
            title: {
                text: this.props.title,
                align: 'left',
                style: {
                    fontSize:  '10px',
                }
            },
            colors: colours,
            stroke: {
                width: [3, 3, 3, 3],
                dashArray: strokeDashArray,
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return val ? val.toFixed(2) : 0.00;
                    },
                },
                // title: {
                //     text: 'Price'
                // },
            },
            xaxis: {
                type: 'datetime',
                labels: {
                    datetimeUTC: false
                }
            },
            tooltip: {
                shared: false,
                y: {
                    formatter: function (val) {
                        return val ? val.toFixed(2) : 0.00;
                    }
                }

            },
            legend: {
                show: showLegend, // FIXME don't show on mobile (or when screen size < x)
                fontSize: '10px',
            }

        };


        return (
            <ReactApexChart options={options} series={series} type="area" height={250}/>
        );

    }

}