import React, {Component} from "react";
import {Grid} from "semantic-ui-react";

export default class ProviderPrices extends Component {

    getStyle(isHigh, isLow) {
        if (isHigh) {
            return {background: "rgba(0, 150, 20, 0.07)", padding: 0, margin: 0}
        }
        if (isLow) {
            return {background: "rgba(240, 40, 40, 0.07)", padding: 0, margin: 0}
        }
        return {padding: 0, margin: 0}
    }

    render() {
        return (
            <Grid stretched centered celled='internally' style={{padding: 0, margin: 0}}>
                <Grid.Row style={this.getStyle(this.props.isHomePriceHigh, this.props.isHomePriceLow)}>
                    {this.props.homePrice}
                </Grid.Row>
                {this.props.showDraw &&<Grid.Row style={this.getStyle(this.props.isDrawPriceHigh, this.props.isDrawPriceLow)}>
                    {this.props.drawPrice}
                </Grid.Row>}
                <Grid.Row style={this.getStyle(this.props.isAwayPriceHigh, this.props.isAwayPriceLow)}>
                    {this.props.awayPrice}
                </Grid.Row>
            </Grid>

        )
    };

}