import React from 'react';
import {MapContainer, TileLayer, useMap, ZoomControl} from 'react-leaflet'
import CovidExposureSiteMarkers from "./CovidExposureSiteMarkers";

export default function MapView() {

    return (
        <React.Fragment>
            <MapContainer
                scrollWheelZoom={false}
                style={{height: "100vh"}}
                zoomControl={false}
                tap={false}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <ZoomControl position={"bottomright"}/>
                <CovidExposureSiteMarkers/>
            </MapContainer>
        </React.Fragment>
    );
}