import React, {Component} from "react";
import {Grid} from "semantic-ui-react";
import OptionFluctuationsChart from "./OptionFluctuationsChart";

export default class Fluctuations extends Component {

    // e.g. Home/Draw/Away
    buildSeriesFromAggregatedOption(ao) {

        const series = [];
        // iterate through providers
        //for (const [index, provider] of ao.optionProviders.entries()) {
        if (!ao) {
            return series;
        }
        for (const [key, provider] of Object.entries(ao.optionProviders)) {
            // e.g. sportsbet-au
            const providerSeries = [];
            if (provider.optionDetails.price_points) {
                provider.optionDetails.price_points.forEach(pp => {
                    providerSeries.push(
                        {
                            x: new Date(pp.recorded_time * 1000),
                            y: pp.price.win_dec
                        }
                    );
                });
            }

            series.push(
                {
                    provider: key,
                    data: providerSeries
                }
            );
        }

        return series;
    }

    buildOptionFluctuation(market, role) {

        const opt = market.aggregatedOptions[role];
        if (!opt) {
            return null;
        }

        const series = this.buildSeriesFromAggregatedOption(opt);

        return {
            name: opt.name,
            series: series
        }
    }

    render() {

        const homeOpt = this.buildOptionFluctuation(this.props.market, "Home");
        const drawOpt = this.buildOptionFluctuation(this.props.market, "Draw");
        const awayOpt = this.buildOptionFluctuation(this.props.market, "Away");

        const showDraw = drawOpt !== null;

        return (
            <Grid columns={showDraw ? 3 : 2} stackable>
                <Grid.Column>
                    <OptionFluctuationsChart
                        title={homeOpt.name}
                        series={homeOpt.series}
                    />
                </Grid.Column>
                {showDraw &&
                    <Grid.Column>
                        <OptionFluctuationsChart
                            title={drawOpt.name}
                            series={drawOpt.series}
                        />
                    </Grid.Column>
                    }
                <Grid.Column>
                    <OptionFluctuationsChart
                        title={awayOpt.name}
                        series={awayOpt.series}
                    />
                </Grid.Column>
            </Grid>
        );
    }
}