import React, {Component} from "react";
import {Grid, Image, Segment} from 'semantic-ui-react'
import MatchSummary from "./MatchSummary";
import "./DayMatches.css";
import bbetAuLogo from "../../assets/images/au/bbet_au.png";
import betdeluxeAuLogo from "../../assets/images/au/betdeluxe_au.png";
import ladbrokesAuLogo from "../../assets/images/au/ladbrokes_au.png";
import nedsAuLogo from "../../assets/images/au/neds_au.png";
import palmerbetAuLogo from "../../assets/images/au/palmerbet_au.png";
import pointsbetAuLogo from "../../assets/images/au/pointsbet_au.png";
import robWaterhouseAuLogo from "../../assets/images/au/robwaterhouse_au.png";
import sportsbetAuLogo from "../../assets/images/au/sportsbet_au.png";
import tabAuLogo from "../../assets/images/au/tab_au.png";

export default class DayMatches extends Component {

    render() {

        const matchSummaries = [];
        for (const [, match] of this.props.matches.entries()) {
            matchSummaries.push(
                <MatchSummary match={match} key={match.id}/>
            );
        }

        return (
            <Segment style={{fontSize: "11px"}}>
                <Grid columns={16}>
                    <Grid.Row color={'black'} style={{margin: 0, padding: 5}}>
                        {/*TODO move to headerRow component?*/}
                        <Grid.Column width={6} textAlign='left' verticalAlign='middle' as='h5'>
                            {this.props.renderedDate}
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={bbetAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="BBet" centered bordered />
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={betdeluxeAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="BetDeluxe" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={ladbrokesAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="Ladbrokes" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={nedsAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="Neds" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={palmerbetAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="PalmerBet" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={pointsbetAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="PointsBet" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={robWaterhouseAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="RobWaterhouse"
                                   centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={sportsbetAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="SportsBet" centered bordered/>
                        </Grid.Column>
                        <Grid.Column style={{padding: 0, margin: 0}}>
                            <Image src={tabAuLogo} style={{width: 24, height: 72, borderColor: "white"}} alt="TAB" centered bordered/>
                        </Grid.Column>
                    </Grid.Row>
                    {matchSummaries}
                </Grid>
            </Segment>
        );
    }

}