import React, {Component} from 'react';
import {Menu, Tab} from 'semantic-ui-react'
import EventList from "../components/EventList";

export default class SoccerTabs extends Component {

    renderTabContent(competition) {
        return <Tab.Pane attached={false} key={competition} style={{padding: "0", border: "0"}}>
            <EventList sport="soccer" competition={competition}/>
        </Tab.Pane>
    }

    render() {
        const panes = [
            {
                menuItem: (
                    <Menu.Item key="soccer-epl" style={{fontSize: "13px"}}>
                        EPL
                    </Menu.Item>),
                render: () => this.renderTabContent("england-premier-league")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-aleague" style={{fontSize: "13px"}}>
                        A-League
                    </Menu.Item>),
                render: () => this.renderTabContent("australia-a-league")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-bundesliga" style={{fontSize: "13px"}}>
                        Bundesliga
                    </Menu.Item>),
                render: () => this.renderTabContent("germany-bundesliga")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-mls" style={{fontSize: "13px"}}>
                        MLS
                    </Menu.Item>),
                render: () => this.renderTabContent("usa-mls")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-laliga" style={{fontSize: "13px"}}>
                        La Liga
                    </Menu.Item>),
                render: () => this.renderTabContent("spain-laliga")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-ligue1" style={{fontSize: "13px"}}>
                        Ligue 1
                    </Menu.Item>),
                render: () => this.renderTabContent("france-ligue-1")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-seriea" style={{fontSize: "13px"}}>
                        Serie A
                    </Menu.Item>),
                render: () => this.renderTabContent("italy-serie-a")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-champions-league" style={{fontSize: "13px"}}>
                        UEFA Champions League
                    </Menu.Item>),
                render: () => this.renderTabContent("europe-champions-league")
            },
            {
                menuItem: (
                    <Menu.Item key="soccer-europa-league" style={{fontSize: "13px"}}>
                        UEFA Europa League
                    </Menu.Item>),
                render: () => this.renderTabContent("europe-europa-league")
            },
            {
                menuItem: (
                    <Menu.Item key="coming-up" style={{fontSize: "13px"}}>
                        Coming Up
                    </Menu.Item>),
                render: () => this.renderTabContent("")
            },
        ];

        return (
            <Tab menu={{pointing: true, className: "wrapped" }} panes={panes} />
        );
    }


}
