import {
  DataGrid,
  GridColumnHeaderParams,
  GridColumns,
} from '@material-ui/data-grid';
import { useExposureSitesContext } from '../contexts/ExposureSitesContext';
import dayjs from 'dayjs';
import { buildTableRows, Location, TableRow } from './TableRow';
import React, { useEffect, useState } from 'react';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import { FormControl, Select } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExposureSiteInfoDialog from './ExposureSiteInfoDialog';
import { ExposureSite } from '../model/ExposureSite';
import {TypographyTypeMap} from "@material-ui/core/Typography/Typography";

const columns: GridColumns = [
  {
    field: 'location',
    headerName: 'Location',
    width: 230,
    sortable: false,
    renderCell: (gridCellParams) => {
      const location = gridCellParams.value as Location;
      let color: string;
      let contactType: string;
      switch (location.contactType) {
        case 'Close':
          color = 'red'
          contactType = 'Close Contact';
          break;
        case 'Casual':
          color = '#F16A05CE'
          contactType = 'Casual Contact';
          break;
        case 'Interstate':
          color = 'red'
          contactType = 'Interstate';
          break;
        default :
          contactType = 'Low Risk';
          color = '#F1B605CE'
      }
      return (
        location && (
          <div>
            <Typography variant={'body2'} style={{ fontSize: 11 }}>
              {location.location}
            </Typography>
            <Typography
              variant={'body2'}
              style={{ fontSize: 11, padding: 0, margin: 0 }}
            >
              {location.address}
            </Typography>
            <Typography
              variant={'body2'}
              style={{ fontWeight: 600, fontSize: 11 }}
            >
              {location.suburb}
            </Typography>
            <Typography
                variant={'body2'}
                style={{ fontWeight: "bold", fontSize: 11, color: color }}
            >
              {contactType}
            </Typography>
          </div>
        )
      );
    },
  },

  {
    field: 'day',
    headerName: '',
    width: 55,
    hideSortIcons: true,
    renderHeader: (params: GridColumnHeaderParams) => {
      return <div />;
    },
    // valueFormatter: (params) => {
    //     return dayjs.unix(params.value as number).format('DD/MM/YY');
    // },
    renderCell: (gridCellParams) => {
      return (
        <div>
          <Typography variant={'body2'} style={{ fontSize: 11 }}>
            {dayjs
              .unix(gridCellParams.value ? (gridCellParams.value as number) : 0)
              .format('DD/MM/YY')}
          </Typography>
        </div>
      );
    },
  },
  {
    field: 'exposureSite',
    headerName: '',
    width: 50,
    sortable: false,
    filterable: false,
    align: 'right',
    renderCell: (gridCellParams) => {
      const es = gridCellParams.value as ExposureSite;

      return <ExposureSiteInfoDialog exposureSite={es} />;
    },
  },
];

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function TableView() {
  const [tableRows, setTableRows] = useState([] as TableRow[]);

  const classes = useStyles();

  const [exposureSitesState, setExposureSitesState] = useExposureSitesContext();

  useEffect(() => {
    // console.log('building table rows');
    const tableRows = buildTableRows(
      exposureSitesState.exposureSites,
      exposureSitesState.selectedState
    );
    setTableRows(tableRows);
    // console.log('finished building table rows');
  }, [exposureSitesState.exposureSites, exposureSitesState.selectedState]);

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    // console.log('name=' + e.target.name);
    // console.log('value=' + e.target.value);
    const selectedState: string = e.target.value as string;
    setExposureSitesState({
      ...exposureSitesState,
      selectedState: selectedState,
    });
  };

  // console.log('tableViewRender. len=' + tableRows.length);

  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <Select
          native
          value={exposureSitesState.selectedState}
          onChange={handleChange}
          inputProps={{
            name: 'state',
            id: 'state',
          }}
        >
          <option aria-label="None" value="" />
          <option value={'ACT'}>ACT</option>
          <option value={'NSW'}>NSW</option>
          <option value={'NT'}>NT</option>
          <option value={'QLD'}>QLD</option>
          <option value={'SA'}>SA</option>
          <option value={'TAS'}>TAS</option>
          <option value={'VIC'}>VIC</option>
          <option value={'WA'}>WA</option>
        </Select>
      </FormControl>

      <div style={{ height: '100%', width: '100%', fontSize: 10 }}>
        <DataGrid
          rows={tableRows}
          columns={columns}
          pageSize={20}
          disableSelectionOnClick
          rowHeight={70}
        />
      </div>
    </React.Fragment>
  );
}
