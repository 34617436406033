import { ExposureSite } from '../model/ExposureSite';

export interface TableRow {
  id: string;
  state: string;
  location: Location;
  day: number;
  timeText: string;
  contactType: string;
  onMap: boolean;
  exposureSite: ExposureSite;
}

export interface Location {
  address: string;
  location: string;
  suburb: string;
  contactType: string;
}

export function buildTableRows(
  exposureSites: ExposureSite[],
  selectedState: string
): TableRow[] {
  const tableRows = [] as TableRow[];
  for (const es of exposureSites) {
    if (es.state !== selectedState) {
      continue;
    }
    const tr = tableRowFromExposureSite(es);
    tableRows.push(tr);
  }

  tableRows.sort((r1,r2) => r2.day - r1.day);

  return tableRows;
}

export function tableRowFromExposureSite(es: ExposureSite): TableRow {
  return {
    id: es.id,
    state: es.state,
    location: {
      address: es.address,
      location: es.location,
      suburb: es.suburb,
      contactType: es.contact_type
    },
    day: es.day_unix_seconds,
    timeText: es.time_text,
    contactType: es.contact_type,
    onMap: es.longitude !== null && es.longitude !== 0,
    exposureSite: es,
  };
}
