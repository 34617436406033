import { ExposureSite } from '../model/ExposureSite';
import axios from 'axios';

const BASE_URL = 'https://brettniven.dev';
const EXPOSURE_SITES_URL = `${BASE_URL}/api/exposuresites`;

const axiosInstance = axios.create();

export async function getExposureSites(): Promise<ExposureSite[]> {
  let url = EXPOSURE_SITES_URL;
  // FIXME use a DevMode switch
  // url = './../testdata/exposuresites.json';
  const response = await axiosInstance.get(url);
  return response.data as ExposureSite[];
}
