import React from 'react';
import {MapContainer, TileLayer} from 'react-leaflet'
import OSMChangeSetMarkers from "./components/OSMChangeSetMarkers";
import {Container, Segment} from "semantic-ui-react";

const defaultCenterLat = 30.0444;
const defaultCenterLon = 31.2357;
const defaultZoom = 2;

export default function OSMRecentChangeSets() {

    return (
        <React.Fragment>
            <Container fluid textAlign="center" className="pageFooter">
                <Segment inverted>
                    This shows recent OpenStreetMap changes, pulled from the OSM ChangeSet feed
                    <br/>
                    The plot data is loaded from a NodeJS service. See <a
                    href="https://brettniven.dev/changesets/all" target="_blank">/changesets/all</a>
                    <br/>
                    Click on the plot points to see the ChangeSet details
                </Segment>
            </Container>
            <MapContainer center={[defaultCenterLat, defaultCenterLon]}
                          zoom={defaultZoom}
                          scrollWheelZoom={false}
                          style={{height: "80vh"}}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <OSMChangeSetMarkers/>
            </MapContainer>
        </React.Fragment>
    );
}