import React, {Component} from "react";
import {Button, Divider, Grid, Popup} from 'semantic-ui-react';
import Competitors from "./Competitors";
import ProviderPrices from "./ProviderPrices";
import Fluctuations from "./Fluctuations";

export default class MatchSummary extends Component {

    state = {
        fluctuationsActive: false
    };

    handleFlucClick = (e, titleProps) => {
        const {fluctuationsActive} = this.state;
        const newActive = !fluctuationsActive;
        this.setState({fluctuationsActive: newActive})
    };

    render() {

        const providerPrices = [];
        const providerMargins = [];
        for (const [, pp] of Object.entries(this.props.match.providerPrices)) {
            providerPrices.push(
                <Grid.Column key={pp.provider} style={{padding: 0, margin: 0}}>
                    <ProviderPrices
                        homePrice={pp.homePrice}
                        isHomePriceHigh={pp.isHomePriceHigh}
                        isHomePriceLow={pp.isHomePriceLow}
                        drawPrice={pp.drawPrice}
                        isDrawPriceHigh={pp.isDrawPriceHigh}
                        isDrawPriceLow={pp.isDrawPriceLow}
                        awayPrice={pp.awayPrice}
                        isAwayPriceHigh={pp.isAwayPriceHigh}
                        isAwayPriceLow={pp.isAwayPriceLow}
                        showDraw={this.props.match.showDraw}
                    />
                </Grid.Column>
            );
            providerMargins.push(
                <Grid.Column key={pp.provider + ":margin"} textAlign='center' style={{padding: 0, margin: 0}}>
                    <b>{pp.margin}</b>
                </Grid.Column>
            );
        }

        const flucsActive = this.state.fluctuationsActive;

        return (

            <React.Fragment>
                <Grid.Row>
                    <Grid.Column width={1} textAlign='left' verticalAlign='middle'>
                        <b>{this.props.match.renderedTime}</b>
                    </Grid.Column>
                    <Grid.Column width={2} textAlign='left' verticalAlign='middle'>
                        <div>
                            <div className="center">{this.props.match.competition}</div>
                            <div style={{color: "rgba(0, 0, 0, 0.5)"}}>{this.props.match.country.name}</div>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Competitors home={this.props.match.home} away={this.props.match.away} showDraw={this.props.match.showDraw}/>
                    </Grid.Column>
                    {providerPrices}
                    <Grid.Column width={1} style={{padding: 0, margin: 0}}>
                        <Grid centered celled='internally' style={{padding: 0, margin: 0}}>
                            <Grid.Row style={{color: "rgba(0, 0, 0, 0.5)", padding: 0, margin: 0}}>
                                {this.props.match.highHomePrice}
                            </Grid.Row>
                            {this.props.match.showDraw && <Grid.Row style={{color: "rgba(0, 0, 0, 0.5)", padding: 0, margin: 0}}>
                                {this.props.match.highDrawPrice}
                            </Grid.Row>}
                            <Grid.Row style={{color: "rgba(0, 0, 0, 0.5)", padding: 0, margin: 0}}>
                                {this.props.match.highAwayPrice}
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={3} textAlign='left'>
                        <Popup
                            content='Fluctuations'
                            key='Fluctuations'
                            trigger={<Button circular icon='line graph' size='mini' toggle active={flucsActive}
                                             onClick={this.handleFlucClick}/>}
                        />
                    </Grid.Column>
                    <Grid.Column width={3} textAlign='right'>
                        <b>Margin %</b>
                    </Grid.Column>
                    {providerMargins}
                    <Grid.Column width={1} style={{padding: 0, margin: 0, textAlign: 'center'}}>
                        <b>{this.props.match.lowAggregatedMargin}</b>
                    </Grid.Column>
                </Grid.Row>
                {flucsActive &&
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Fluctuations market={this.props.match.primaryMarket}/>
                    </Grid.Column>
                </Grid.Row>
                }
                <Divider/>
            </React.Fragment>
        );
    }

}