import React, {Component} from "react";
import {Grid} from 'semantic-ui-react'
import ReactCountryFlag from "react-country-flag";

export default class Competitors extends Component {

    render() {

        let homeFlag = null;
        let awayFlag = null;

        if (this.props.home.country.iso3166_2 !== this.props.away.country.iso3166_2) {
            homeFlag = <ReactCountryFlag
                countryCode={this.props.home.country.iso3166_2}
                title={this.props.home.country.name}/>;
            awayFlag = <ReactCountryFlag
                countryCode={this.props.away.country.iso3166_2}
                title={this.props.away.country.name}/>;
        }

        return (
            <Grid celled='internally'>
                <Grid.Row>
                    <div align="center">
                    {homeFlag}
                    {this.props.home.name}
                    </div>
                </Grid.Row>
                {this.props.showDraw && <Grid.Row>
                    Draw
                </Grid.Row>}
                <Grid.Row>
                    <div align="center">
                    {awayFlag}
                    {this.props.away.name}
                    </div>
                </Grid.Row>
            </Grid>

        );
    }

}