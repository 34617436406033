import React from 'react';
import {useHistory} from 'react-router-dom';
import {Card, Container, Segment,} from 'semantic-ui-react';
import priceComparisonImg from './assets/images/cards/price_comparison.png';
import osmRecentChangesetsImg from './assets/images/cards/openstreetmap_changesets.png';
import exposureSitesImg from './assets/images/cards/exposure_sites.png';

const tools = [
    {
        name: 'Price Comparisons',
        description: 'View pricing (odds) from various bookmakers of all major sporting codes',
        tech: 'Backend: Go, MongoDB, GRPC',
        tech2: 'Frontend: React, Vanilla JavaScript, Semantic UI',
        endpoint: '/pricecomparison',
        image: priceComparisonImg,
    },
    {
        name: 'OpenStreetMap ChangeSets',
        description: `View recent OpenStreetMap changes, pulled from the OSM ChangeSet feed`,
        tech: `Backend: NodeJS, TypeScript, MongoDB`,
        tech2: 'Frontend: React, TypeScript, Leaflet, Material UI',
        endpoint: '/osmrecentchangesets',
        image: osmRecentChangesetsImg,
    },
    {
        name: 'Covid Exposure Sites',
        description: `View Covid-19 exposure sites as reported by the QLD Government`,
        tech: `Backend: Go, MongoDB`,
        tech2: 'Frontend: React, TypeScript, Leaflet, Material UI',
        endpoint: '/exposuresites',
        image: exposureSitesImg,
    },
];


export default function Home() {
    const history = useHistory();

    return <React.Fragment>
        <Container fluid textAlign="center" className="pageFooter">
            <Segment inverted>
                Software Engineer in Brisbane, Australia
                <br/>
                This site is hosted in Google Cloud Platform, Kubernetes
                <br/>
            </Segment>
        </Container>
        <Container fluid>
            <Segment inverted style={{minHeight: 680}}>
                <Card.Group centered>
                    {tools.map(tool =>
                        <Card onClick={() => history.push(tool.endpoint)}>
                            <Card.Content centered>
                                <Card.Header>
                                    {tool.name}
                                </Card.Header>
                            </Card.Content>
                            <Card.Content centered>
                                <img
                                    src={tool.image}
                                    alt={tool.name}
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0)',
                                        //backgroundColor: '#123e65',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        height: 150,
                                        maxWidth: 180,
                                        objectFit: 'contain',
                                    }}
                                />
                            </Card.Content>
                            <Card.Content centered>
                                <Card.Description>
                                    {tool.description}
                                </Card.Description>
                            </Card.Content>
                            <Card.Content extra>
                                {tool.tech}
                                <br/>
                                {tool.tech2}
                            </Card.Content>
                        </Card>)}
                </Card.Group>
            </Segment>
        </Container>
        <Container fluid textAlign="center" className="pageFooter">
            <Segment inverted>
                &copy;
                {' '}
                {new Date().getFullYear()}
                {' '}
                Brett Niven
            </Segment>
        </Container>
    </React.Fragment>
}
