import React, {FC} from 'react';
import './App.css';

import CovidExposureSitesWrapper from "./covidstats/components/CovidExposureSitesWrapper";
import {Route, Switch} from 'react-router-dom';
import OSMRecentChangeSets from "./osmchanges/OSMRecentChangeSets";
import Home from "./home/Home";
import PriceComparison from "./priceshield/PriceComparison";

const App: FC = () => (
    <Switch>
        <Route path="/" component={Home} exact/>
        <Route path="/pricecomparison" component={PriceComparison}/>
        <Route path="/osmrecentchangesets" component={OSMRecentChangeSets}/>
        <Route path="/exposuresites" component={CovidExposureSitesWrapper}/>
    </Switch>
);

export default App;
