import React from 'react';
import {Theme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InfoIcon from "@material-ui/icons/Info";
import {ExposureSite} from "../model/ExposureSite";
import dayjs from "dayjs";
import Grid from "@material-ui/core/Grid";
import ExposureSitePopupContent from "./ExposureSitePopupContent";
import {useMap} from "react-leaflet";
import ContactType from "./ContactType";
import {Divider} from "@material-ui/core";

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface Props {
    exposureSite: ExposureSite;
}

export default function ExposureSiteInfoDialog(props: Props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const renderedDate = dayjs
        .unix(props.exposureSite.day_unix_seconds)
        .format('dddd D MMMM YYYY'); // e.g. 'Wednesday 8 July 2020'

    // @ts-ignore
    return (
        <div>
            <IconButton
                color="primary"
                aria-label="open drawer"
                onClick={handleClickOpen}
                edge="end"
                // className={clsx(classes.menuButton, open && classes.hide)}
            >
                <InfoIcon />
            </IconButton>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogContent dividers>
                    <Typography variant={'body2'}>
                        <b>{props.exposureSite.location}</b>
                    </Typography>
                    <Typography variant={'body2'}>
                        {renderedDate} : {props.exposureSite.time_text}
                    </Typography>
                    <Typography variant={'body2'}>
                        {props.exposureSite.address}
                    </Typography>
                    <Typography variant={'body2'}>
                        <b>{props.exposureSite.suburb}</b>
                    </Typography>
                    <br/>
                    <ContactType contactType={props.exposureSite.contact_type} />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}