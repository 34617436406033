import React, { useEffect } from 'react';
import MapView from './MapView';
import TableView from './TableView';
import { useExposureSitesContext } from '../contexts/ExposureSitesContext';
import { getExposureSites } from '../controller/Controller';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/ListAlt';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { Size, useWindowSize } from '../../hooks/UseWindowSize';

import SiteInfoIconWithDialog from './SiteInfoIconWithDialog';
import {FormControl, Select} from "@material-ui/core";
import {Variant} from "@material-ui/core/styles/createTypography";
import NoNewDataDialog from "./NoNewDataDialog";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  formControl: {
    minWidth: 70,
  },
  title: {
    flexGrow: 1,
  },
  icon: {
    fill: 'white',
  },
}));

export default function CovidExposureSites() {
  const [exposureSitesState, setExposureSitesState] = useExposureSitesContext();

  const size: Size = useWindowSize();
  // console.log('size=' + size.height + ';' + size.width);
  // const drawerDefaultVisible: boolean = (size.width &&
  //   size.width > 500) as boolean;

  const fetchExposureSites = async (): Promise<void> => {
    const fetchedExposureSites = await getExposureSites();

    const filtered = fetchedExposureSites.filter(x => exposureSitesState.selectedContactType === 'All' || exposureSitesState.selectedContactType === x.contact_type);

    // console.log('wrapperFetch=' + fetchedExposureSites.length);
    setExposureSitesState({
      ...exposureSitesState,
      exposureSites: filtered,
    });

    // console.log("opening drawer")
    // if (size.width &&
    //     size.width > 500) {
    //   setOpen(true);
    // }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    // setDrawerToggled(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    // setDrawerToggled(true);
  };

  useEffect(() => {
    // console.log(
    //   'wrapperUseEffect. len=' + exposureSitesState.exposureSites.length
    // );

    fetchExposureSites();
  }, [exposureSitesState.selectedContactType]);
  // TODO maybe have contact type as dependency

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [drawerToggled, setDrawerToggled] = React.useState(false);

  let variant: Variant = 'subtitle1';
  if (size.width && size.width >= 500) {
    variant = 'h6';
  }

  const handleContactTypeChange = (
      e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    // console.log('name=' + e.target.name);
    // console.log('value=' + e.target.value);
    const selectedContactType: string = e.target.value as string;
    setExposureSitesState({
      ...exposureSitesState,
      selectedContactType: selectedContactType,
    });
  };

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant={variant} noWrap className={classes.title}>
            Covid-19 Exposure Sites
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
                native
                value={exposureSitesState.selectedContactType}
                onChange={handleContactTypeChange}
                inputProps={{
                  name: 'contactType',
                  id: 'contactType',
                  classes: {
                    icon: classes.icon,
                  },
                }}
                style={{color: 'white'}}
            >
              <option aria-label="None" value="Contact Type" />
              <option value={'All'} style={{color: '#123e65'}}>All</option>
              <option value={'Close'} style={{color: '#123e65'}}>Close</option>
              <option value={'Casual'} style={{color: '#123e65'}}>Casual</option>
              <option value={'LowRisk'} style={{color: '#123e65'}}>Low</option>
            </Select>
          </FormControl>

          <SiteInfoIconWithDialog />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <TableView />
      </Drawer>

      <MapView />

      <NoNewDataDialog />

    </React.Fragment>
  );
}
